import React from 'react'
import {Element} from 'react-scroll'
import styles from './AboutUs.module.scss'
import AboutUsItem from './AboutUsItem'

const AboutUs = () => {
    return (
        <section className={styles.section}>
            <svg className={styles.blob} width="835" height="539" viewBox="0 0 835 539" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_489_6)">
                    <path
                        d="M71.4811 197.637C97.9926 149.111 146.196 118.4 199.497 103.678C366.788 57.4693 737.549 -31.228 773.844 78.952C784.966 112.715 796.248 133.211 806.992 144.826C821.159 160.141 870.75 187.09 870.75 207.953V403.345C870.75 412.082 864.9 419.872 856.55 422.441C465.015 542.896 301.52 485.408 118.375 418.025C0.535313 374.67 32.5382 268.918 71.4811 197.637Z"
                        fill="#FF781D"/>
                </g>
                <defs>
                    <filter id="filter0_d_489_6" x="0" y="0" width="906.75" height="538.5" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="10"/>
                        <feGaussianBlur stdDeviation="18"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_489_6"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_489_6" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <Element name="services"/>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.tag}>сервисы</div>
                    <div className={styles.title}>
                        Мы доставляем успех каждый день. Что мы можем сделать для вас?
                    </div>
                    <div className={styles.desc}>
                        Быстрые и надежные бухгалтерские решения для вашего бизнеса.
                    </div>
                    <a href="#" className={styles.link}>
                        услуги
                    </a>
                </div>
                <div className={styles.right}>
                    <AboutUsItem
                        img="/images/about-1.svg"
                        text="Бухгалтерское сопровождение"
                    />
                    <AboutUsItem
                        img="/images/about-2.svg"
                        text="Консультации и обучене"
                    />
                    <AboutUsItem
                        img="/images/about-3.svg"
                        text="Регистрация предприятий"
                    />
                    <AboutUsItem
                        img="/images/about-4.svg"
                        text="Декларации 3-НДФЛ"
                    />
                </div>
            </div>
        </section>
    )
}

export default AboutUs
