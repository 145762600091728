import Btn from "../ui/btn";
import {useRef} from "react";
import emailjs from "@emailjs/browser";

const ModalMenuBig = ({isActive, onClose}) => {


    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_x1ufd6n', 'template_d1frh7o', form.current, 'Hd3QZyBBK6PKELqzH')
            .then((result) => {
                alert('Ваше сообщение отправлено. Мы свяжемся с вами в ближайшее время.')
                form.current.reset();
            }, (error) => {
                alert('Ваше сообщение не отправлено, попробуйте позже')
            });
    };
    return (
        <div className={`modal-menu-bg  ${isActive ? 'show' : 'hide'}`}>
            <div className={`modal-menu-block ${isActive ? 'show' : 'hide'}`}>
                <div>
                    <form style={{marginTop: '10px', display: 'grid', justifyItems: 'center'}} ref={form}
                          onSubmit={sendEmail}>
                        <input required type="text" placeholder="Ваше имя" name="name"/>
                        <input required type="tel" placeholder="Номер телефона" name="phone"/>
                        <select required placeholder="Правовая форма" name="legalForm">
                            <option value="" disabled selected hidden>Организационно-правовая форма</option>
                            <option value="OOO">ООО</option>
                            <option value="ИП">ИП</option>
                            <option value="АО">АО</option>
                            <option value="НО">НО</option>
                        </select>
                        <select required placeholder="Вид деятельности" name="activity">
                            <option value="" disabled selected hidden>Вид деятельности</option>
                            <option value="Производство">Производство</option>
                            <option value="Строительство">Строительство</option>
                            <option value="Розничная торговля">Розничная торговля</option>
                            <option value="Оптовая торговля">Оптовая торговля</option>
                            <option value="Онлайн торговля">Онлайн торговля</option>
                            <option value="Оказание услуг">Оказание услуг</option>

                        </select>
                        <select required placeholder="Система налогообложения"
                                name="taxation">
                            <option value="" disabled selected hidden>Система налогообложения</option>
                            <option value="Общая">Общая</option>
                            <option value="Упращенная дох - расх">Упрощенная дох - расх</option>
                            <option value="Упращенная доход">Упрощенная доход</option>
                            <option value="Патентная">Патентная</option>
                            <option value="ЕСХН">ЕСХН</option>
                            <option value="НПД">НПД</option>

                        </select>
                        <select required placeholder="Кол-во работников" name="workers">
                            <option value="" disabled selected hidden>Кол-во работников</option>
                            <option value="до 10">до 10</option>
                            <option value="от 10 до 60">от 10 до 60</option>
                            <option value="свыше 60">свыше 60</option>

                        </select>
                        <textarea name="message" placeholder='Комментарий'/>
                        <Btn name="Узнать стоимость" isMiniBtn={true} mt='22.5px' isInversion={false} isSubmit={true}/>
                    </form>
                </div>
                <svg
                    onClick={onClose}
                    className='close'
                    width='28'
                    height='28'
                    viewBox='0 0 28 28'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <g clipPath='url(#clip0_243_142)'>
                        <path
                            d='M14 28C6.28031 28 0 21.7197 0 14C0 6.28031 6.28031 0 14 0C21.7197 0 28 6.28031 28 14C28 21.7197 21.7197 28 14 28ZM14 1.99992C7.38325 1.99992 1.99992 7.38325 1.99992 14C1.99992 20.6167 7.38325 26.0001 14 26.0001C20.6167 26.0001 26.0001 20.6167 26.0001 14C26.0001 7.38325 20.6167 1.99992 14 1.99992Z'
                            fill='black'
                        />
                        <path
                            d='M19.0001 20C18.7441 20 18.4882 19.9024 18.293 19.7069L8.29309 9.70704C7.90234 9.3163 7.90234 8.68357 8.29309 8.2931C8.68356 7.90257 9.31651 7.90235 9.70703 8.2931L19.7069 18.293C20.0977 18.6837 20.0977 19.3165 19.7069 19.7069C19.6142 19.8 19.5041 19.8738 19.3828 19.9241C19.2615 19.9744 19.1314 20.0002 19.0001 20Z'
                            fill='black'
                        />
                        <path
                            d='M8.99992 20C8.74399 20 8.48832 19.9024 8.29309 19.7069C7.90234 19.3164 7.90234 18.6835 8.29309 18.293L18.293 8.29309C18.6837 7.90234 19.3164 7.90234 19.7069 8.29309C20.0974 8.68356 20.0977 9.31651 19.7069 9.70703L9.70703 19.7069C9.61432 19.8 9.50411 19.8738 9.38275 19.9241C9.2614 19.9744 9.13129 20.0002 8.99992 20Z'
                            fill='black'
                        />
                    </g>
                    <defs>
                        <clipPath id='clip0_243_142'>
                            <rect width='28' height='28' fill='white'/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    );
};

export default ModalMenuBig;