import React from 'react'
import styles from './AboutUs.module.scss'
const AboutUsItem = ({ img, text }) => {
	return (
		<div className={styles.aboutItem}>
			<img src={img} alt="text" />
			<div className={styles.text}>{text}</div>
		</div>
	)
}

export default AboutUsItem
