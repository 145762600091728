import Logo from "./logo";
import ContactMenu from "./contact-menu";
import Btn from "../ui/btn";
import Form from "./form";
import {Element, Link} from 'react-scroll'

const Footer = () => {
    return (
        <footer>
            <div className="footer-bg"></div>
            <div className="footer-about-block">
                <Logo/>
                <p className="footer-text link-desc">Мы помогаем бизнесу развиваться и, постоянно повышая свою
                    квалификацию,
                    защищаем бизнес от необдуманных поступков, разъясняя сложное и противоречивое законодательство.</p>
                <ContactMenu reverse={false}/>
                <h5 className="sub-title white">Услуги</h5>
                <div className="footer-nav">
                    <Link className="footer-link mini-link eee" to="services">Бухгалтерское сопровождение</Link>
                    <Link className="footer-link mini-link eee" to="services">Консультации<br/> и
                        обучение</Link>
                    <Link className="footer-link mini-link eee" to="services">Регистрация предприятий</Link>
                    <Link className="footer-link mini-link eee" to="services">Декларации<br/> 3-НДФЛ</Link>
                </div>
            </div>
            <div className="reviews">
                <h5 className="sub-title white">Наши отзывы</h5>
                <div className="review-block">
                    <h5 className="sub-title grey">Мария</h5>
                    <p className="footer-text link-desc grey">
                        Индивидуальный подход к каждому. Профессиональная и грамотная помощь. Оперативность в решении
                        вопросов.
                        Быстро и просто решили вопрос с налоговым инспектором. Помогли с заполнением декларации, после
                        чего у инспектора отпали вопросы и проверка завершилась для нас с положительным результатом.</p>
                </div>
                <div className="review-block">
                    <h5 className="sub-title grey">Александр</h5>
                    <p className="footer-text link-desc grey">Обратились в 2022м году за полноценным бухгалтерским
                        обслуживанием. На момент обращения, бухгалтерия была в полном запущении, получали кучу
                        требований из налоговой,действующий бухгалтер не справлялся и готовился уйти. Девушки из ML
                        быстро восстановили наш учет и урегулировали отношения с налоговой. А также благодаря
                        оптимизации бухгалтерской программы, упростили работу нашим продавцам. И в целом стало как-то
                        все проще и прозрачнее. Работаем вместе уже полтора года... довольны, рекомендуем. Наша фирма
                        занимается ритуальными услугами и продажей памятников.</p>
                </div>
                <div className="buttons">
                    <a href="https://yandex.ru/maps/org/ml_bukhgalteriya/205176528428/?ll=35.899913%2C56.875758&z=16">
                        <Btn isMiniBtn={true} isInversion={true} mt="30px" name="все"/>
                    </a>
                    <a href="https://yandex.ru/maps/org/ml_bukhgalteriya/205176528428/?add-review=true&ll=35.899913%2C56.875758&z=16">
                        <Btn isMiniBtn={true} isInversion={false} mt="30px" name="написать"/>
                    </a>
                </div>
            </div>
            <Element name="get-footer" className="footer-form">
                <h5 className="sub-title white">Напишите нам, если у вас есть вопросы.</h5>
                <Form/>
            </Element>
        </footer>
    );
};

export default Footer;