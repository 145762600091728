const ModalMenu = ({ items, onClose, isActive }) => {
	return (
		<div style={{ zIndex: '998' }} className={`modal-menu-block ${isActive ? 'show' : 'hide'}`}>
			{items}
			<svg
				onClick={onClose}
				className='close'
				width='28'
				height='28'
				viewBox='0 0 28 28'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<g clipPath='url(#clip0_243_142)'>
					<path
						d='M14 28C6.28031 28 0 21.7197 0 14C0 6.28031 6.28031 0 14 0C21.7197 0 28 6.28031 28 14C28 21.7197 21.7197 28 14 28ZM14 1.99992C7.38325 1.99992 1.99992 7.38325 1.99992 14C1.99992 20.6167 7.38325 26.0001 14 26.0001C20.6167 26.0001 26.0001 20.6167 26.0001 14C26.0001 7.38325 20.6167 1.99992 14 1.99992Z'
						fill='black'
					/>
					<path
						d='M19.0001 20C18.7441 20 18.4882 19.9024 18.293 19.7069L8.29309 9.70704C7.90234 9.3163 7.90234 8.68357 8.29309 8.2931C8.68356 7.90257 9.31651 7.90235 9.70703 8.2931L19.7069 18.293C20.0977 18.6837 20.0977 19.3165 19.7069 19.7069C19.6142 19.8 19.5041 19.8738 19.3828 19.9241C19.2615 19.9744 19.1314 20.0002 19.0001 20Z'
						fill='black'
					/>
					<path
						d='M8.99992 20C8.74399 20 8.48832 19.9024 8.29309 19.7069C7.90234 19.3164 7.90234 18.6835 8.29309 18.293L18.293 8.29309C18.6837 7.90234 19.3164 7.90234 19.7069 8.29309C20.0974 8.68356 20.0977 9.31651 19.7069 9.70703L9.70703 19.7069C9.61432 19.8 9.50411 19.8738 9.38275 19.9241C9.2614 19.9744 9.13129 20.0002 8.99992 20Z'
						fill='black'
					/>
				</g>
				<defs>
					<clipPath id='clip0_243_142'>
						<rect width='28' height='28' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</div>
	)
}

export default ModalMenu
