import { useEffect } from 'react'
import Advantages from './components/Advantages'
import AboutUs from './components/about-us/AboutUs'
import Footer from './components/footer'
import Header from './components/header'
import Partners from './components/partners'

const aspectRatios = [
	'(max-aspect-ratio: 9/16)',
	'(max-aspect-ratio: 9/18)',
	'(max-aspect-ratio: 9/21)',
	'(max-aspect-ratio: 9/16)',
	'(max-aspect-ratio: 3/4)',
]
let desc
// Check each aspect ratio
for (const aspectRatio of aspectRatios) {
	const mediaQuery = window.matchMedia(aspectRatio)

	if (mediaQuery.matches) {
		desc = false
		import('./styles/adaptive/global.scss')
		import('./styles/adaptive/footer.mobile.scss')
		import('./styles/adaptive/header.mobile.scss')
		// import('./styles/adaptive/about-us.mobile.scss')
		import('./styles/adaptive/partners.mobile.scss')
	} else {
		desc = true
		import('./styles/global.scss')
		import('./styles/footer.scss')
		import('./styles/header.css')
		// import('./styles/about-us.scss')
		import('./styles/partners.scss')
	}
}

function App() {
	useEffect(() => {
		const width = window.screen.width
		const height = window.screen.height

		if (width >= 2560 && height >= 1440) {
			// Если экран 2K
			document.body.style.zoom = '150%'
		}
		if (width >= 3840 && height >= 2160) {
			// Если экран 4K
			document.body.style.zoom = '200%'
		}
	}, [])
	return (
		<div className='App'>
			<Header desc={desc} />
			<Advantages />
			<AboutUs />
			<Partners desc={desc} />
			<Footer />
		</div>
	)
}

export default App
