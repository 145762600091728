import logo from '../assets/images/Frame-9.webp'
import logo_inv from '../assets/images/logo-inv.webp'

const Logo = ({isInversion}) => {
    return (
        isInversion ?
            <img src={logo_inv} alt="" height="77.25" width="255"/>
            :
            <img src={logo} alt="" height="77.25" width="255"/>
    );
};

export default Logo;