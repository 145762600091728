import '../styles/offer.scss'

const Offer = () => {
    return (
        <div className="offer">
            <h1>Стройте свой бизнес<br/> вместе с нами</h1>
        </div>
    );
};

export default Offer;