import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {A11y, Pagination} from "swiper/modules";


const Partners = ({desc}) => {
    if (desc) {
        return (
            <section className="partners-section">
                <div className="partners-section-bg"></div>
                <h5 className="sub-title black">Наши партнеры</h5>
                <div className="partners-wrapper">
                    <a className="partner" href="https://lol.ru">
                        <img src="https://acdn.tinkoff.ru/static/documents/ece38c8f-4d3b-4b22-a7ee-05449e43c2c5.svg"
                             alt="tinkoff"/>
                    </a>
                    <a className="partner" href="https://lol.ru">
                        <img src="https://static.insales-cdn.com/images/products/1/6558/475019678/logo.jpg"
                             alt="сбер банк"/>
                    </a>
                    <a className="partner" href="https://lol.ru">
                        <img src="https://im.kommersant.ru/gboxtexts/sp_com/logos/043.jpg"
                             alt="альфа"/>
                    </a>
                    <a className="partner" href="https://lol.ru">
                        <img
                            src="https://pro-rko.com/wp-content/uploads/2019/06/Tochka-bank-logo_2ae22f75dbbcea7797c76b90e7ccd4c4.png"
                            alt="точка"/>
                    </a>

                </div>
            </section>
        );
    } else {
        return (

            <section className="partners-section">
                <h5 className="sub-title black">Наши партнеры</h5>
                <div className="partners-section-bg"></div>
                <Swiper
                    modules={[Pagination, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{clickable: true}}

                >
                    <SwiperSlide><a className="partner" href="https://lol.ru">
                        <img src="https://acdn.tinkoff.ru/static/documents/ece38c8f-4d3b-4b22-a7ee-05449e43c2c5.svg"
                             alt="tinkoff"/>
                    </a></SwiperSlide>
                    <SwiperSlide><a className="partner" href="https://lol.ru">
                        <img src="https://static.insales-cdn.com/images/products/1/6558/475019678/logo.jpg"
                             alt="сбер банк"/>
                    </a></SwiperSlide>
                    <SwiperSlide><a className="partner" href="https://lol.ru">
                        <img src="https://im.kommersant.ru/gboxtexts/sp_com/logos/043.jpg"
                             alt="альфа"/>
                    </a></SwiperSlide>
                    <SwiperSlide><a className="partner" href="https://lol.ru">
                        <img
                            src="https://cdnn21.img.ria.ru/images/07e4/0a/01/1578066714_0:312:1427:1115_600x0_80_0_0_751df50f69de671cf614a45e11fd1606.jpg"
                            alt="псб"/>
                    </a></SwiperSlide>
                </Swiper>
            </section>
        )
    }

};

export default Partners;