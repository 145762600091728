import {useState} from 'react'
import {Link} from 'react-scroll'
import Btn from '../ui/btn'
import Nav from './Nav'
import ContactMenu from './contact-menu'
import Logo from './logo'
import ModalMenu from './modalMenu'
import ModalMenuBig from './modalMenuBig'
import Offer from './offer'


const Header = ({desc}) => {
    const [isModalMenuVisible, setIsModalMenuVisible] = useState(false)

    const toggleModalMenu = () => {
        setIsModalMenuVisible(prev => !prev)
    }
    const [isBigModalMenuVisible, setIsBigModalMenuVisible] = useState(false)

    const toggleBigModalMenu = () => {
        setIsBigModalMenuVisible(prev => !prev)
    }

    if (desc) {
        return (
            <>
                <ModalMenuBig isActive={isBigModalMenuVisible} onClose={toggleBigModalMenu}/>
                <header className='d-flex'>
                    <div>
                        <div className='bg_header'></div>
                        <div className='d-flex just-ct logo-block'>
                            <Logo/>
                            <Offer/>
                        </div>
                        <div className='line'>
                            <div className='active-line'></div>
                        </div>
                        <Nav onClose={toggleModalMenu} openBigModal={toggleBigModalMenu}/>
                    </div>
                    <ContactMenu reverse={true}/>
                </header>
                <div className='title-block'>
                    <h4 className='site-title-desc'>для Вашего бизнеса</h4>
                    <h1 className='site-title'>бухгалтерские услуги</h1>
                    <Link to='get-footer'>
                        <Btn name='Получить консультацию' mt='15px' isInversion={false} isMiniBtn={false}/>
                    </Link>
                </div>
            </>
        )
    } else {
        return (
            <>
                <ModalMenuBig isActive={isBigModalMenuVisible} onClose={toggleBigModalMenu}/>
                <ModalMenu
                    isActive={isModalMenuVisible}
                    onClose={toggleModalMenu}
                    items={
                        <div>
                            <Logo isInversion={true}/>
                            <div className='d-flex'>
                                <div className='icons-nav'>
                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <g clipPath='url(#clip0_243_147)'>
                                            <path
                                                d='M20.8889 12.7367V23.4011C20.8889 24.1433 20.2889 24.7522 19.5555 24.7522H14.3422V18.7767H9.65775V24.7522H4.44442C3.71108 24.7522 3.11108 24.1456 3.11108 23.4011V12.7367C3.11108 12.2545 3.36442 11.81 3.77553 11.5678L11.3311 7.13667C11.6978 6.92112 12.3 6.92112 12.6666 7.13667L20.2222 11.5678C20.5889 11.7833 20.8889 12.31 20.8889 12.7367Z'
                                                fill='#5D5D5D'
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id='clip0_243_147'>
                                                <rect width='24' height='24' fill='white'/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <g clipPath='url(#clip0_243_160)'>
                                            <path
                                                d='M12.0281 6.75004C7.03597 6.73597 2.98597 10.786 3.00004 15.7781C3.0141 20.7141 7.13089 24.75 12.0668 24.75H21V15.8168C21 10.8809 16.9641 6.7641 12.0281 6.75004ZM12.8016 20.3344C12.566 20.5418 12.2742 20.6473 11.9227 20.6473C11.5711 20.6473 11.2793 20.5418 11.0438 20.3344C10.8082 20.127 10.6922 19.8598 10.6922 19.5363C10.6922 19.2129 10.8082 18.9457 11.0438 18.7383C11.2793 18.5309 11.5711 18.4254 11.9227 18.4254C12.2742 18.4254 12.566 18.5309 12.8016 18.7383C13.0371 18.9457 13.1531 19.2129 13.1531 19.5363C13.1531 19.8598 13.0336 20.1234 12.8016 20.3344ZM14.8828 14.4528C14.7387 14.7516 14.5067 15.0574 14.1867 15.3668L13.4309 16.0699C13.2164 16.2774 13.0653 16.4883 12.9844 16.7063C12.9 16.9242 12.8543 17.1985 12.8473 17.5324H10.9629C10.9629 16.8926 11.0367 16.3863 11.1809 16.0172C11.3285 15.641 11.5711 15.3106 11.884 15.0539C12.2074 14.7832 12.4571 14.5336 12.6258 14.3086C12.791 14.0942 12.8789 13.8305 12.8789 13.5633C12.8789 12.9024 12.5942 12.5684 12.0246 12.5684C11.7785 12.5614 11.543 12.6668 11.3883 12.8567C11.2266 13.05 11.1387 13.3102 11.1317 13.6406H8.90979C8.92034 12.7617 9.19456 12.0797 9.73948 11.591C10.2844 11.1024 11.0473 10.8563 12.0281 10.8563C13.0055 10.8563 13.7649 11.0813 14.3028 11.5348C14.8406 11.9883 15.1078 12.6282 15.1078 13.4614C15.1043 13.8024 15.0305 14.1434 14.8828 14.4528Z'
                                                fill='#5D5D5D'
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id='clip0_243_160'>
                                                <rect width='24' height='24' fill='white'/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <g clipPath='url(#clip0_243_150)'>
                                            <path
                                                d='M19.4999 6.75H13.2655C12.853 6.75 12.2766 6.98893 11.9852 7.28011L3.43773 15.8276C2.85409 16.4106 2.85409 17.3664 3.43773 17.9487L9.80202 24.3128C10.3843 24.8958 11.3392 24.8958 11.9224 24.3122L20.4699 15.766C20.761 15.4748 21 14.8975 21 14.4859V8.25008C21 7.42522 20.3247 6.75 19.4999 6.75ZM16.4998 12.7501C15.6713 12.7501 14.9997 12.0778 14.9997 11.25C14.9997 10.4209 15.6713 9.74994 16.4998 9.74994C17.3282 9.74994 18 10.4209 18 11.25C18.0001 12.0778 17.3282 12.7501 16.4998 12.7501Z'
                                                fill='#5D5D5D'
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id='clip0_243_150'>
                                                <rect width='24' height='24' fill='white'/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <g clipPath='url(#clip0_243_157)'>
                                            <path
                                                d='M9.37481 14.8129H4.6875C3.75712 14.8129 3 14.0557 3 13.1254V8.4375C3 7.50712 3.75712 6.75 4.6875 6.75H9.37481C10.3052 6.75 11.0623 7.50712 11.0623 8.4375V13.1254C11.0623 14.0557 10.3058 14.8129 9.37481 14.8129ZM19.3125 14.8129H14.6246C13.6943 14.8129 12.9371 14.0557 12.9371 13.1254V8.4375C12.9371 7.50712 13.6943 6.75 14.6246 6.75H19.3125C20.2429 6.75 21 7.50712 21 8.4375V13.1254C21 14.0557 20.2429 14.8129 19.3125 14.8129ZM9.37481 24.7151H4.6875C3.75712 24.7151 3 23.958 3 23.0276V18.3398C3 17.4094 3.75712 16.6523 4.6875 16.6523H9.37481C10.3052 16.6523 11.0623 17.4094 11.0623 18.3398V23.0276C11.0623 23.958 10.3058 24.7151 9.37481 24.7151ZM19.3125 24.7151H14.6246C13.6943 24.7151 12.9371 23.958 12.9371 23.0276V18.3398C12.9371 17.4094 13.6943 16.6523 14.6246 16.6523H19.3125C20.2429 16.6523 21 17.4094 21 18.3398V23.0276C21 23.958 20.2429 24.7151 19.3125 24.7151Z'
                                                fill='#5D5D5D'
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id='clip0_243_157'>
                                                <rect width='24' height='24' fill='white'/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <g clipPath='url(#clip0_243_153)'>
                                            <path
                                                d='M15.7144 6.75H3.82471C2.54271 6.75 1.5 7.79271 1.5 9.07471V19.4937C1.5 19.9872 2.05805 20.2653 2.45197 19.984L5.76317 17.6215C6.04101 17.4236 6.36783 17.3188 6.70911 17.3188H13.992C15.274 17.3188 16.3167 16.2761 16.3167 14.9941V7.35226C16.3167 7.01981 16.0469 6.75 15.7144 6.75ZM12.7891 14.0457H5.83062C5.49817 14.0457 5.22836 13.7763 5.22836 13.4435C5.22836 13.111 5.49817 12.8412 5.83062 12.8412H12.7891C13.1215 12.8412 13.3913 13.111 13.3913 13.4435C13.3913 13.7763 13.1215 14.0457 12.7891 14.0457ZM12.7891 11.2352H5.83062C5.49817 11.2352 5.22836 10.9658 5.22836 10.6329C5.22836 10.3005 5.49817 10.0307 5.83062 10.0307H12.7891C13.1215 10.0307 13.3913 10.3005 13.3913 10.6329C13.3913 10.9658 13.1215 11.2352 12.7891 11.2352Z'
                                                fill='#5D5D5D'
                                            />
                                            <path
                                                d='M22.057 13.7462V24.1648C22.057 24.6549 21.5017 24.9384 21.105 24.6551L17.7938 22.293C17.516 22.0951 17.1892 21.9903 16.8479 21.9903H9.565C8.283 21.9903 7.2403 20.9472 7.2403 19.6652V18.5233H13.992C15.9381 18.5233 17.5212 16.9402 17.5212 14.9941V11.4211H19.7323C21.0143 11.4211 22.057 12.4642 22.057 13.7462Z'
                                                fill='#5D5D5D'
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id='clip0_243_153'>
                                                <rect width='24' height='24' fill='white'/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <Nav onClose={toggleModalMenu} openBigModal={toggleBigModalMenu}/>
                            </div>
                        </div>
                    }
                />
                <header className='d-flex'>
                    <div>
                        <div className='bg_header'></div>
                        <Logo/>
                        <svg
                            onClick={toggleModalMenu}
                            width='40'
                            height='40'
                            viewBox='0 0 40 40'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M40 8C40 6.89547 39.1045 6 38 6H12C10.8955 6 10 6.89547 10 8C10 9.10453 10.8955 10 12 10H38C39.1045 10 40 9.10445 40 8ZM2 18H38C39.1045 18 40 18.8955 40 20C40 21.1045 39.1045 22 38 22H2C0.89547 22 0 21.1045 0 20C0 18.8955 0.89547 18 2 18ZM20 30H38C39.1045 30 40 30.8955 40 32C40 33.1045 39.1045 34 38 34H20C18.8955 34 18 33.1045 18 32C18 30.8955 18.8955 30 20 30Z'
                                fill='#E1E2EF'
                            />
                        </svg>
                    </div>
                </header>
                <div className='title-block'>
                    <h4 className='site-title-desc'>Вашего бизнеса</h4>
                    <h1 className='site-title'>надежный помощник</h1>
                    <Link to='get-footer'>
                        <Btn name='Получить консультацию' mt='15px' isInversion={true} isMiniBtn={false}/>
                    </Link>
                </div>
                <ContactMenu reverse={true}/>
            </>
        )
    }
}

export default Header
