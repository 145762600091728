import {useRef} from "react";
import emailjs from '@emailjs/browser';
import Btn from "../ui/btn";


const Form = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_x1ufd6n', 'template_vsa1kx1', form.current, 'Hd3QZyBBK6PKELqzH')
            .then((result) => {
                alert('Ваше сообщение отправлено. Мы свяжемся с вами в ближайшее время')
                form.current.reset();

            }, (error) => {
                alert('Ваше сообщение не отправлено, попробуйте позже')
            });
    };
    return (
        <form style={{marginTop: '10px'}} ref={form} onSubmit={sendEmail}>
            <input required className="mini-link" type="text" placeholder="Ваше имя" name="name"/>
            <input required className="mini-link" type="tel" placeholder="Номер телефона" name="phone"/>
            <textarea required className="mini-link" name="message" placeholder='Ваш вопрос'/>
            <Btn name="Получить консультацию" isMiniBtn={true} mt='22.5px' isInversion={false} isSubmit={true}/>
        </form>
    );
};

export default Form;