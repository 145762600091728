const Btn = ({name, mt, isInversion, isMiniBtn, isSubmit}) => {
    let classBtn = "default-btn"
    if (isInversion) {
        classBtn += " light-btn"
    }
    if (isMiniBtn) {
        classBtn += " mini-btn"
    }
    console.log(isSubmit ? 'submit' : '')
    return (
        <button type={isSubmit ? 'submit' : ''} style={{marginTop: mt}} className={classBtn}>
            {name}
        </button>
    );
};

export default Btn;