const AdvantagesItem = ({svg, h3, desc, isActive}) => {
    return (
        <div className={isActive ? 'advantages__item advantages__item-active' : 'advantages__item'}>
            {svg}
            <div>
                <div className={isActive ? 'advantages__bg-active advantages__bg' : 'advantages__bg'}></div>
                <h3 className="advantages__item-title text-center">{h3}</h3>
                <div className="link-desc text-center">{desc}
                </div>
                <svg className={isActive ? "advantages__img-active" : "advantages__img"} width="30" height="30"
                     viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" fill="#1E1E1E"/>
                    <g clipPath="url(#clip0_0_1)">
                        <rect width="1920" height="3322" transform="translate(-1199 -982)" fill="white"/>
                        <g filter="url(#filter0_d_0_1)">
                            <rect x="-889" y="-207" width="1300" height="250" rx="20" fill="white"/>
                        </g>
                        <g filter="url(#filter1_d_0_1)">
                            <rect x="-130" y="-237" width="300" height="310" rx="20" fill="#E45C00"/>
                        </g>
                        <rect width="30" height="30" rx="15" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M16.9396 15L12.2198 10.2803C12.0832 10.1388 12.0076 9.94935 12.0093 9.7527C12.011 9.55605 12.0899 9.36794 12.229 9.22889C12.368 9.08983 12.5561 9.01095 12.7528 9.00924C12.9494 9.00754 13.1389 9.08313 13.2803 9.21975L18.5303 14.4698C18.6001 14.5393 18.6554 14.622 18.6931 14.713C18.7308 14.804 18.7502 14.9015 18.7502 15C18.7502 15.0985 18.7308 15.196 18.6931 15.287C18.6554 15.378 18.6001 15.4607 18.5303 15.5303L13.2803 20.7803C13.1389 20.9169 12.9494 20.9925 12.7528 20.9908C12.5561 20.989 12.368 20.9102 12.229 20.7711C12.0899 20.6321 12.011 20.4439 12.0093 20.2473C12.0076 20.0507 12.0832 19.8612 12.2198 19.7197L16.9396 15Z"
                              fill="#FF781D"/>
                    </g>
                    <defs>
                        <filter id="filter0_d_0_1" x="-925" y="-233" width="1372" height="322"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="10"/>
                            <feGaussianBlur stdDeviation="18"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                        </filter>
                        <filter id="filter1_d_0_1" x="-166" y="-263" width="372" height="382"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="10"/>
                            <feGaussianBlur stdDeviation="18"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                        </filter>
                        <clipPath id="clip0_0_1">
                            <rect width="1920" height="3322" fill="white" transform="translate(-1199 -982)"/>
                        </clipPath>
                    </defs>
                </svg>


            </div>
        </div>
    );
};
export default AdvantagesItem;