import {Link} from 'react-scroll'
import LinkItem from '../ui/link'

const Nav = ({onClose, openBigModal}) => {
    return (
        <>
            <nav className='header-nav d-flex just-ct'>
                <Link offset={-200} to='main' onClick={() => onClose(false)}>
                    <LinkItem name='Главная' nameDesc='напишите нам'/>
                </Link>
                <Link offset={-100} to='whywe' onClick={() => onClose(false)}>
                    <LinkItem name='почему мы' nameDesc='преимущества'/>
                </Link>
                <Link offset={-200} onClick={openBigModal}>
                    <LinkItem name='стоимость' nameDesc='узнайте сколько'/>
                </Link>
                <Link offset={-200} to='services' onClick={() => onClose(false)}>
                    <LinkItem name='услуги' nameDesc='предлагаем вам'/>
                </Link>
                <Link offset={-200} to='get-footer' onClick={() => onClose(false)}>
                    <LinkItem name='отзывы' nameDesc='отклики клиентов'/>
                </Link>
            </nav>
        </>
    )
}

export default Nav
