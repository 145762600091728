import '../styles/global.scss'

const LinkItem = ({name, nameDesc, isMail}) => {
    return (
        <>
            <div style={isMail === true ? {textTransform: 'lowercase'} : {}} className="link">
                {name}
            </div>
            <div className="link-desc">{nameDesc}</div>
        </>

    );
};

export default LinkItem;